<template>
    <div class="portal news-list-wrapper">
        <div class="banner-img-wrapper">
            <h2 class="cover-title">{{ 'গোপনীয়তার নীতিমালা' }}</h2>
            <img src="../../../assets/images/cover-img.jpg" alt="">
        </div>
        <div>
            <b-container>
                <ol class="breadcrumb custom-bread">
                    <li class="breadcrumb-item"><router-link to="/portal/home" target="_self" class="">{{ $t('portal.home') }}</router-link></li>
                    <li class="breadcrumb-item active"><span aria-current="location">{{ $t('portal.policy') }}</span></li>
                </ol>
            </b-container>
        </div>
        <b-container>
            <b-row class="pb-4">
                <b-col sm="12" md="12">
                    <p>বাংলাদেশ সরকার এবং বাণিজ্য মন্ত্রণালয় আপনার ব্যক্তিগত তথ্যাবলীর গোপনীয়তা বজায় রাখবে। আপনি আপনার ব্যক্তিগত কোন তথ্যাবলী প্রদান
                        না করেও এই ওয়েবসাইট ব্রাউজ করতে পারবেন। যদি আপনি আপনার সম্পর্কে কোন তথ্য দিতে ইচ্ছুক থাকেন, সেক্ষেত্রে আমরা সে তথ্যাবলী সংরক্ষণ
                        করব। আমরা সেক্ষেত্রে অন্যান্য সরকারী অফিস এবং সংস্থার সাথে এসব তথ্যাবলী বিনিময় করতে পারি। যেসব তথ্যাবলী সংগ্রহ করা হবে তা শুধুমাত্র
                        দাপ্তরিক উদ্দেশ্যে, আভ্যন্তরীণ পর্যালোচনা, ওয়েবসাইটের কনটেন্টের মানোন্নয়নের ক্ষেত্রে ব্যবহৃত হবে। বাণিজ্যিক উদ্দেশ্যে আপনার তথ্যাবলী ব্যবহার করা
                        হবে না। কোন ধরনের আইনী প্রয়োজন যেমন-সার্চ ওয়ারেন্ট কিংবা কোর্ট অর্ডারের ক্ষেত্রে বাংলাদেশ সরকার যে কোন ব্যক্তির ব্যক্তিগত তথ্য প্রকাশ করতে
                        পারে। আপনি যখন ওয়েবসাইটের মাধ্যমে ই-মেইল, কোন জরিপে অংশগ্রহণ করেন অথবা কোন বিষয়ে মতামত প্রদান অথবা যোগাযোগের ক্ষেত্রে কোন
                        তথ্য প্রদান করে থাকেন সেক্ষেত্রে আপনার ব্যক্তিগত তথ্যাবলী সংগ্রহ করা সম্ভব হবে। আপনার প্রশ্নের সঠিক উত্তর প্রদানের জন্য আপনার তথ্যগুলো
                        অন্যান্য সরকারী এজেন্সী অথবা ব্যক্তির নিকট তা প্রেরণ করা হতে পারে।</p><br>
                    <p>ওয়েবসাইট ব্যবহারকারীগণকে ই-মেইল অথবা ফরম পূরণে যেসব তথ্য বিনিময় করে থাকেন তাদেরকে গোপনীয় তথ্য যেমন-নিরাপত্তা, পাসওয়ার্ড, ক্রেডিট
                    কার্ড নং যদি না প্রয়োজন হয় সেক্ষেত্রে তা না প্রদান করার জন্য অনুরোধ করা হচ্ছে। বাণিজ্য মন্ত্রণালয় এক্ষেত্রে এ ধরনের তথ্যের অপব্যবহার বা
                    ক্ষতিপূরণ দিতে বাধ্য থাকবে না।</p><br>
                    <p>এই ওয়েবসাইটটিতে সরকারের অন্যান্য এজেন্সী বা সংগঠনের সাথে লিংক প্রদান করা হয়েছে। আপনি যখন এই সাইট ব্যতীত অন্য সাইট ব্যবহার করছেন
                    সেক্ষেত্রে নতুন সাইটের গোপনীয়তার নীতিমালা প্রযোজ্য হবে।</p><br>
                    <p>কোন প্রকার নোটিশ ব্যতীত বাণিজ্য মন্ত্রণালয় যেকোন সময় এই নীতিমালা সংশোধন করতে পারে। অথবা এই ওয়েবসাইটের ব্যবহারকারীগণকে
                    নোটিশের মাধ্যমে জানাতে পারে। যেকোন তথ্যাদি যা বর্তমান নীতিমালার মাধ্যমে সংরক্ষণ করা হয়েছিল তা এসব শর্তাবলী মেনে চলবে। নীতিমালার
                    পরিবর্তনের পর যদি কোন তথ্যাদি সংগ্রহ করা হয় তা অবশ্যই পরিবর্তিত নীতিমালার মাধ্যমে পালনীয় হবে।</p>
                    <br><br>
                    <address>
                    <b>আরো তথ্যের জন্য যোগাযোগ করুন</b><br/>
                    বাণিজ্য মন্ত্রণালয়<br>
                    বাংলাদেশ সচিবালয়<br>
                    রমনা, ঢাকা-১০০০<br>
                    বাংলাদেশ।
                    </address>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>

export default {
    name: 'Policy',
    components: {},
    data () {
        return {
            loading: false
        }
    },
    created () {
        window.scrollTo(0, 0)
    },
    computed: {
        checkBn () {
            return (this.$i18n.locale === 'bn') ? 1 : 0
        }
    },
    methods: {
    }
}
</script>
